import { motion } from 'framer-motion';
import React from 'react';

export default function ScrollEffect({ Element = motion.div, children = undefined, className = '' }: React.PropsWithChildren<{ Element: typeof motion.div, className?: string }>) {
  return (
    <Element
      initial={{ opacity: 0, y: 30 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true, amount: 0.25 }}
      transition={{ duration: 0.5, ease: [0, 0, 0.5, 1] }}
      className={className}
    >
      { children }
    </Element>
  );
}
